@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;900&family=Ubuntu:wght@700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@600;900&family=Ubuntu:wght@500;700&display=swap");

html {
  overflow-x: hidden;
}

.row {
  margin: 0 !important;
}

h1 {
  font-family: "Ubuntu", sans-serif;
  color: #e9292c;
  font-size: 3.4em !important;
  font-weight: 800;
}

h2 {
  font-family: "Ubuntu", sans-serif;
  color: #e9292c;
}

h3 {
  font-family: "Ubuntu", sans-serif;
  color: #e9292c;
}

p {
  font-family: "Open Sans", sans-serif;
  color: #000;
}

body {
  display: grid;
  font-family: "Open Sans", sans-serif;
  margin: 0;
}

#root {
  display: grid;
  grid-template-areas:
    "warning"
    "header"
    "main"
    "footer";
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr auto;
}

.ant-menu.ant-menu-dark {
  background-color: #000 !important;
  min-height: 80px;
  padding-top: 20px;
}

.ant-menu-vertical {
  background: #000 !important;
}

.ant-menu-item-selected {
  background: #e9292c !important;
}
.ant-menu-item-selected a {
  color: #fff !important;
}

.ant-menu-item:hover {
  background: #e9292c !important;
  color: #d3d3d3 !important;
}

.ant-btn-primary {
  border: #000 !important;
  background: #000 !important;
  transition: 0.3;
}

.ant-btn-primary:hover {
  border: #e9292c !important;
  background: #e9292c !important;
}

.ant-breadcrumb-separator {
  color: #e9292c !important;
  font-size: 1em;
}

.ant-breadcrumb a:hover {
  color: #e9292c !important;
}

.ant-checkbox-wrapper {
  line-height: normal !important;
}

.ant-slider-track {
  background-color: #000 !important;
}

.ant-slider-handle {
  border: solid 2px #e9292c !important;
}

.ant-modal-close-x {
  color: #fff !important;
}

.ant-modal-title {
  color: #fff !important;
}

.ant-modal-content {
  background-color: #000 !important;
}

.ant-modal-header {
  background-color: #e9292c !important;
}

.ant-checkbox-inner {
  background-color: #000 !important;
  border-color: #e9292c !important;
}

.ant-checkbox-inner:after {
  color: #000 !important;
}

.ant-checkbox-checked {
  background-color: #e9292c !important;
}

.ant-checkbox-checked::after {
  border-color: #e9292c !important;
}

.slider-wrapper {
  border: 1px solid #000;
}

li.borderless {
  border: 0 none;
}

.list-group-mine .list-group-item {
  background-color: #f7f7f7;
}

.list-group-mine li {
  font-weight: bolder;
  font-size: 1.5em;
}

.btn-edit {
  border: #e9292c !important;
  background: #e9292c !important;
  height: 3em !important;
  transition: 0.3;
}

.btn-edit:hover {
  border: #000 !important;
  background: #000 !important;
}

.footer {
  grid-area: footer;
  background-color: #000;
  margin-top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
}

.foot a {
  transition: 0.5s;
  color: #e9292c;
}

.foot a:hover {
  color: #fff;
}

.foot p {
  transition: 0.5s;
  color: #e9292c;
  text-align: left;
}

.foot p:hover {
  color: #fff;
}

.foot-logo {
  float: left;
  width: 120px;
  height: auto;
  padding: 22px;
}

.link-icon {
  font-size: 26px;
  float: left;
}

.white {
  color: #fff;
}
.black {
  color: #000;
  transition: 0.5s;
}
.black:hover {
  color: #e9292c;
}

.bg {
  background-color: #f7f7f7;
  transition: 0.5s;
}

.bg:hover {
  background-color: #fff;
}

.bgstill {
  background-color: #f7f7f7;
}

.bg-black {
  background-color: #000;
}

.angle {
  background-color: #000;
  transform: skewY(-3deg);
  transform-origin: top left;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: -10;
}

.angle-product {
  background-color: #000;
  transform: skewY(-3deg);
  transform-origin: top left;
  top: 30px;
  bottom: 0;
  right: 0;
  left: 0;
  height: 150px;
  width: 100%;
  position: relative;
  z-index: -10;
}

.white-angle {
  width: 100%;
  height: 100px;
  background-color: #fff;
  transform: skewY(-3deg);
}

.angle-ret {
  transform: skewY(3deg);
  color: #fff;
  /* position: absolute; */
}

.angle-ret h1 {
  color: #e9292c;
}

.negative {
  margin-top: -70px;
}

.hp-box {
  text-align: center;
  width: 40%;
  float: left;
  background-color: #f7f7f7;
}

.hp-sol {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 45%;
  height: auto;
}

.hp-sol img {
  object-fit: fill;
  width: 100%;
  height: auto;
  overflow: hidden;
  float: right;
}

.hp-sol h4 {
  font-family: "Source Sans Pro", sans-serif;
  color: #e9292c;
}

.hp-sol p {
  font-family: "Open Sans", sans-serif;
  color: #000;
}

.about-box {
  border: 1px solid #000;
}

.case-box {
  width: 100%;
  height: 100%;
  background-color: #f7f7f7;
  background-image: url(images/Farrans-case-study-home.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 2px solid #000;
}

.back {
  width: 100%;
  height: auto;
  background-color: #f7f7f7;
  background-image: url(images/want-to-learn-more-about-tico.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  overflow: hidden;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.read {
  line-height: 26px;
  font-size: 16px;
}

.bullet-point-container {
  display: flex !important;
  align-items: baseline;
}

.bullet-point-container .label {
  padding-left: 1rem;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.stackParent {
  position: relative;
}

.stack-Txt {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  text-align: center;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 8em;
  font-family: "Ubuntu", sans-serif;
  font-weight: 600;
}
.drop-margin {
  margin-top: 21%;
}

.spacer {
  width: 100%;
  height: 20px;
}

.hover {
  transition: 0.5s;
}

.hover:hover {
  opacity: 0.5;
}

.main {
  min-height: auto;
}

.riser {
  z-index: 11000;
}

.containA {
  position: relative;
}
.containB {
  position: relative;
  width: auto;
  height: auto;
  top: -28px;
  z-index: 1;
  background-color: #fff;
}
